import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import { useHashState } from 'hooks/useHashState'
import { ComponentSubNavBar } from 'types/generated/contentful-types'
import { useSubNavBarContext } from 'context/SubNavBarContext'
import { useStickyHeaderContext } from 'context/StickyHeaderContext'
import { useGlobalNavContext } from 'context/GlobalNavContext'
import { useRouter } from 'next/router'

const SubNavBar = ({ navItemsCollection }: ComponentSubNavBar) => {
  const [activeHash, setActiveHash] = useState('')
  const navBarRef = useRef<HTMLDivElement | null>(null)
  const activeSection = useHashState({
    ref: navBarRef,
  })

  // eslint-disable-next-line
  const { stickyHeaderHeight, isPresent: isStickyPresent } =
    useStickyHeaderContext()

  const { setIsPresent, setSubNavHeight } = useSubNavBarContext()
  const { isPresent: isGlobalNavPresent, showHeader } = useGlobalNavContext()

  const router = useRouter()
  // register subnav height and presence in context when mounted
  useEffect(() => {
    setIsPresent(true)

    if (navBarRef.current) {
      setSubNavHeight(navBarRef.current.offsetHeight)
    }
    return () => {
      setIsPresent(false)
      setSubNavHeight(0)
    }
  }, [setIsPresent, setSubNavHeight])

  const [giveOffset, setGiveOffset] = useState(false)
  const [startPosition, setStartPosition] = useState(0)

  useEffect(() => {
    if (navBarRef.current) {
      setStartPosition(navBarRef.current?.getBoundingClientRect().top)
    }
  }, [router.isReady])

  useEffect(() => {
    const activeHashItem = navItemsCollection?.items.find(
      (item) => item?.url === activeSection
    )
    if (activeHashItem) {
      setActiveHash(activeHashItem.url ?? '')
    }
  }, [activeSection, navItemsCollection])

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY + 84 <= startPosition) {
        setGiveOffset(false)
      } else {
        setGiveOffset(true)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [startPosition])

  const setOffset = isStickyPresent ? `top-[48px]` : 'top-0'
  const setGlobalOffset =
    isGlobalNavPresent && giveOffset && showHeader
      ? `lg:!top-[85px] !top-[63px] transition-top ease-linear duration-200`
      : 'top-[-1px] transition-top ease-linear lg:duration-200 duration-200'

  return (
    <div
      className={`sticky h-11 md:h-[58px] ${setOffset} ${setGlobalOffset} z-30 justify-center lg:grid`}
      ref={navBarRef}
    >
      <div
        className={`flex flex-row-reverse items-center content-end justify-center h-full bg-sand before:content-[""] before:absolute before:h-[1px] before:w-screen bg-opacity-[.85] backdrop-blur-[.3rem] before:-bottom-0 before:bg-darkShell w-screen px-3 -translate-x-4`}
      >
        <span id="subnav-header" className="sr-only">
          Page Section Navigation
        </span>
        <nav
          aria-labelledby="subnav-header"
          className="w-full overflow-x-scroll overflow-y-hidden md:flex md:justify-center lg:overflow-hidden"
        >
          <ul className="flex flex-row justify-between md:min-w-[600px]">
            {navItemsCollection &&
              navItemsCollection.items.map((item, i) => (
                <li
                  className={`sub-nav-item md:pr-0 mr-6  ${
                    activeHash === item?.url ? ' border-b border-blackSand' : ''
                  }`}
                  key={`SubNavItem_${i}`}
                >
                  <Link
                    href={
                      item?.url?.startsWith('#')
                        ? `${router.asPath.split('#')[0]}${item?.url}`
                        : item?.url ?? ''
                    }
                    scroll={item?.url?.startsWith('#')}
                    shallow={item?.url?.startsWith('#')}
                    className="block h-full uppercase md:fora-text-navigation-1 fora-text-navigation-4"
                    target={item?.newWindow ? '_blank' : undefined}
                    rel={item?.newWindow ? 'noopener noreferrer' : undefined}
                  >
                    {item?.label}
                  </Link>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default SubNavBar
